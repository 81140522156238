import { Component, OnInit } from "@angular/core";
import { Item } from "../models/items";

@Component({
  selector: "app-offers-page",
  templateUrl: "./offers-page.component.html",
  styleUrls: ["./offers-page.component.scss"]
})
export class OffersPageComponent implements OnInit {
  items: Item[] = [
    { name: "Full X30 Engine Kit", rrp: 1979.0, tred_price: 1829.0 },
    { name: "New X30 Engine and Carb", rrp: 1679.0, tred_price: 1579.0 },
    { name: "New X30 Engine", rrp: 1579.0, tred_price: 1479.0 }
  ];

  constructor() {}

  ngOnInit() {}
}
