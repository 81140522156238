import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-gallery-page",
  templateUrl: "./gallery-page.component.html",
  styleUrls: ["./gallery-page.component.scss"]
})
export class GalleryPageComponent implements OnInit {
  // public images = [
  //   "gallery1",
  //   "gallery2",
  //   "gallery3",
  //   "gallery4",
  //   "gallery5",
  //   "gallery7",
  //   "gallery8",
  //   "gallery9",
  //   "gallery10",
  //   "gallery11",
  //   "gallery12",
  //   "gallery13",
  //   "gallery14",
  //   "gallery15",
  //   "gallery16",
  //   "gallery17",
  //   "gallery18",
  //   "gallery19"
  // ];
  public images = [];
  public modalImage = "";
  public selectedIndex = 0;

  constructor() {}

  ngOnInit() {
    const numOfPhotos = 24;
    for (var _i = 1; _i < 24; _i++) {
      this.images.push("gallery" + _i);
    }
  }

  public setImage(imageIndex) {
    this.selectedIndex = imageIndex;
    this.modalImage = this.images[imageIndex];
  }

  public nextImage() {
    if (this.selectedIndex === this.images.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
    this.setImage(this.selectedIndex);
  }

  public prevImage() {
    if (this.selectedIndex == 0) {
      this.selectedIndex = this.images.length - 1;
    } else {
      this.selectedIndex--;
    }
    this.setImage(this.selectedIndex);
  }
}
