import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tred-carousel",
  templateUrl: "./tred-carousel.component.html",
  styleUrls: ["./tred-carousel.component.scss"]
})
export class TredCarouselComponent implements OnInit {
  images = ["banner2", "banner3", "banner4"];

  constructor() {}

  ngOnInit() {}
}
