import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-photo-pop-up",
  templateUrl: "./photo-pop-up.component.html",
  styleUrls: ["./photo-pop-up.component.scss"]
})
export class PhotoPopUpComponent implements OnInit {
  @Input() image: String = "";

  constructor() {}

  ngOnInit() {
    console.log(this.image);
  }
}
