import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageComponent } from "./home-page/home-page.component";
import { TeamPageComponent } from "./team-page/team-page.component";
import { ServicesPageComponent } from "./services-page/services-page.component";
import { ContactPageComponent } from "./contact-page/contact-page.component";
import { OffersPageComponent } from "./offers-page/offers-page.component";
import { AchievementsPageComponent } from "./achievements-page/achievements-page.component";
import { GalleryPageComponent } from "./gallery-page/gallery-page.component";
import { RaceTeamPageComponent } from "./race-team-page/race-team-page.component";
import { EnginePrepPageComponent } from "./engine-prep-page/engine-prep-page.component";
import { KartPrepPageComponent } from "./kart-prep-page/kart-prep-page.component";
import { TfrPageComponent } from "./tfr-page/tfr-page.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "home", component: HomePageComponent },
  { path: "about", component: TeamPageComponent },
  { path: "services", component: ServicesPageComponent },
  { path: "gallery", component: GalleryPageComponent },
  { path: "achievements", component: AchievementsPageComponent },
  { path: "offers", component: OffersPageComponent },
  { path: "race-team", component: RaceTeamPageComponent },
  { path: "engine-prep", component: EnginePrepPageComponent },
  { path: "kart-prep", component: KartPrepPageComponent },
  { path: "tfr", component: TfrPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
