import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TredCarouselComponent } from "./tred-carousel/tred-carousel.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { TeamPageComponent } from "./team-page/team-page.component";
import { ServicesPageComponent } from "./services-page/services-page.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { ContactPageComponent } from "./contact-page/contact-page.component";
import { FooterComponent } from "./footer/footer.component";
import { OffersPageComponent } from "./offers-page/offers-page.component";
import { AchievementsPageComponent } from './achievements-page/achievements-page.component';
import { GalleryPageComponent } from './gallery-page/gallery-page.component';
import { EnginePrepPageComponent } from './engine-prep-page/engine-prep-page.component';
import { KartPrepPageComponent } from './kart-prep-page/kart-prep-page.component';
import { RaceTeamPageComponent } from './race-team-page/race-team-page.component';
import { TfrPageComponent } from './tfr-page/tfr-page.component';
import { PhotoPopUpComponent } from './photo-pop-up/photo-pop-up.component';

@NgModule({
  declarations: [
    AppComponent,
    TredCarouselComponent,
    NavigationComponent,
    TeamPageComponent,
    ServicesPageComponent,
    HomePageComponent,
    ContactPageComponent,
    FooterComponent,
    OffersPageComponent,
    AchievementsPageComponent,
    GalleryPageComponent,
    EnginePrepPageComponent,
    KartPrepPageComponent,
    RaceTeamPageComponent,
    TfrPageComponent,
    PhotoPopUpComponent
  ],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
